body {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: var(--heading_color);
}

h1 {
  font-size: 88px;
  line-height: 1.2;
}

h2 {
  font-size: 42px;
  line-height: 1.2;
}

h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4 {
  font-size: 28px;
  line-height: 1.2;
}

h5 {
  font-size: 24px;
  line-height: 1.2;
}

h6 {
  font-size: 18px;
  line-height: 1.2;
}

@media(max-width:991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 18px;
  }
}

@media(max-width:767px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 16px;
  }
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  display: inline-block;
  text-decoration: none;
}

.section {
  padding: 80px 0px;
}

.sectiontop {
  padding-top: 80px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.form-control {
  height: 43px;
}

.click-button {
  display: inline-block;
  position: fixed;
  bottom: 15px;
  z-index: 666;
  right: 15px;
}

.modal-content .form-group {
  margin-bottom: 10px;
}

.modal-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 20px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
  bottom: 65px;
  right: 22px;
  margin-right: -15px;
  border: 5px solid #a1c1a1;
  background: #fff !important;
  overflow: hidden !important;
  bottom: 96px !important;
  z-index: 1 !important;
  transition: width .15s ease-in-out !important;
  border-radius: 12px !important;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, .02), 0 8px 24px 0 rgba(0, 0, 0, .12) !important;
}

.click-button button {
  border: 0px;
  background: var(--primary_color);
  width: 60px;
  border-radius: 50%;
  padding: 5px;
  height: 60px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  text-align: right;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.sectionbottom {
  padding-bottom: 80px;
}

.sec-title {
  position: relative;
  margin-top: 80px;
}

.sec-title:before {
  content: "";
  position: absolute;
  top: -30px;
  width: 5.2rem;
  height: 1rem;
  background: #d31c2e;
  -webkit-transform: skew(-45deg);
  -ms-transform: skew(-45deg);
  transform: skew(-45deg);
}

:root {
  --primary_color: #14A800;
  --secondary_color: black;
  --heading_color: #000000;
  --subheading_color: #000000;
  --body_text_color: #000000;
}

.primary-color {
  color: #14A800;
}

.secondary-color {
  color: black;
}

.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
}

img {
  width: 100%;
}

.primary-button {
  transition: left 0.3s ease-in-out;
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  background-color: transparent;
  border: 3px solid #14A800;
  color: #14A800;
  border-radius: 5px;
  padding: 12px 48px;
  font-size: 16px;
  font-weight: 700;
}

.primary-button:hover {
  color: black;
  fill: black;
}

svg {
  fill: #14A800;
}

.secondary-button {
  transition: left 0.3s ease-in-out;
  background-color: #14A800 !important;
  color: white !important;
}

.header-sec {
  width: 100%;
  display: block;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.share-butt {
  width: 23px;
  height: 23px;
  margin-left: 10px;
}

header {
  background-color: #F9F9F9;
  width: 100%;
  height: 100%;
  min-height: 70px;
  z-index: 99;
  padding-top: 20px;
}

header .header {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto;

}

.header .logo a {
  font-size: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

header .header .m_links {
  line-height: 70px;
  height: 100%;
  margin-right: auto;
}

header .header .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .header .links li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
  padding: 0 14px;
}

header .header .links li a {
  height: 100%;
  text-decoration: none;
  white-space: nowrap;
  color: black;
  font-size: 15px;
  font-weight: 700;
}

.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

header .header .links li .arrow {
  font-size: 14px;
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-left: 5px;
}

header .header .links li .sub-menu {
  position: absolute;
  top: 70px;
  left: 0;
  line-height: 40px;
  background: #3E8DA8;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 2;
}

header .header .links li:hover .htmlCss-sub-menu,
header .header .links li:hover .js-sub-menu {
  display: block;
}

.header .links li .sub-menu li {
  padding: 0 22px;

}

.header .links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.header .links li .sub-menu .more-arrow {
  line-height: 40px;
}

.header .links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}

.logo {
  margin-right: auto;
}

.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}

.header .search-box {
  position: relative;
  height: 40px;
  width: 40px;
}

.header .search-box i {
  position: absolute;
  height: 100%;
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header .search-box .input-box {
  position: absolute;
  right: calc(100% - 40px);
  top: 80px;
  height: 60px;
  width: 300px;
  background: #3E8DA8;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.header.showInput .search-box .input-box {
  top: 65px;
  opacity: 1;
  pointer-events: auto;
  background: #3E8DA8;
}

.search-box .input-box::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background: #3E8DA8;
  right: 10px;
  top: -6px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search-box .input-box input {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 35px;
  width: 280px;
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  border: none;
}

.header .m_links .sidebar-logo {
  display: none;
}

.header .fa-bars {
  display: none;
}

@media (max-width:991px) {
  .header .menu-overlay {
    position: fixed;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .header .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }

  header .header .links li+li {
    border-top: 1px solid black;
  }

  .header .fa-bars {
    display: block;
  }

  header .header .m_links {
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 75%;
    width: 75%;
    background: #ffffff;
    line-height: 40px;
    padding: 20px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1000;
  }

  .header .m_links .sidebar-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .sidebar-logo .logo-name {
    font-size: 25px;
    color: #fff;
  }

  .sidebar-logo i,
  .header .fa-bars {
    font-size: 25px;
    color: var(--primary_color);
  }

  header .header .links {
    display: block;
    margin-top: 20px;
    border-top: 1px solid black;
  }

  header .header .links li .arrow {
    line-height: 40px;
  }

  header .header .links li {
    display: block;
    padding: 0;
  }

  header .header .links li .sub-menu {
    position: relative;
    top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: none;
  }

  header .header .links li .sub-menu li {
    border-bottom: none;
  }

  .header .links li .sub-menu .more-sub-menu {
    display: none;
    position: relative;
    left: 0;
  }

  .header .links li .sub-menu .more-sub-menu li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .links li:hover .htmlcss-arrow,
  .links li:hover .js-arrow {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .header .links li .sub-menu .more-sub-menu {
    display: none;
  }

  .header .links li .sub-menu .more span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .links li .sub-menu .more:hover .more-sub-menu {
    display: none;
  }

  header .header .links li:hover .htmlCss-sub-menu,
  header .header .links li:hover .js-sub-menu {
    display: none;
  }

  .header .m_links.show1 .links .htmlCss-sub-menu,
  .header .m_links.show3 .links .js-sub-menu,
  .header .m_links.show2 .links .more .more-sub-menu {
    display: block;
  }

  .header .m_links.show1 .links .htmlcss-arrow,
  .header .m_links.show3 .links .js-arrow {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .header .m_links.show2 .links .more-arrow {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@media (max-width:375px) {
  header .header .m_links {
    max-width: 100%;
  }

  .header.showInput .search-box .input-box {
    width: 230px;
  }

  .search-box .input-box input {
    width: 200px;
  }
}

input {
  padding: 12px;
}

.hero_seciton .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero_seciton {
  background-color: #F9F9F9;
  position: relative;
  padding-bottom: 180px;
  padding-top: 20px;
}

.hero_seciton .shape {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 100px;
}

.hero_seciton .button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  margin-top: 80px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.hero_seciton .container {
  position: relative;
  z-index: 5;
}

.what_we_do ul li {
  background: #F2F7F2;
  font-size: 32px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  min-height: 100px;
  width: 23%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 131px;
}

.heading-border {
  text-align: center;
  position: relative;
  margin-bottom: 70px;
}

.heading-border::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  content: "";
  border: 1px solid var(--primary_color);
  margin: auto;
  -webkit-transform: translate(-50%, 20px);
  -ms-transform: translate(-50%, 20px);
  transform: translate(-50%, 20px);
  width: 117px;
}

.button-section {
  text-align: center;
}

.what_we_do ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
}

.button-container input {
  width: 45%;
  border-radius: 10px 0px 0px 10px;
  outline: 0px;
  border-color: transparent;
}

.Schedule h2,
.Schedule p {
  color: white;
  padding-right: 100px;
  margin-bottom: 20px;
}

.Schedule {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 106px 0px 118px 0px;
}

.button-container {
  margin-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Schedule .primary-button {
  border-radius: 0px 10px 10px 0px;
}

.about-section .inner-box {
  max-width: 700px;
  margin: auto;
  margin-bottom: 20px;
}

.why_work .innner-box {
  BACKGROUND: #F2F7F2;
  padding: 20PX;
  min-height: 330px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.why_work .inner-heading h3 {
  font-size: 24px;
  text-align: left;
}

.heading-border.border-left:before {
  left: 0;
  transform: translate(0px, 10px);
  width: 100%;
}

.why_work .border-left {
  margin-bottom: 30px;
}

@media(max-width:992px) {
  .cta-seciton .inner-box {
    justify-content: center !important;
    text-align: center;
  }
}

.chat img {
  width: 25px;
  height: auto;
  object-fit: contain;
}

.chat span {
  margin-right: 16px;
}

.social-side {
  margin-top: 50px;
}

.chat i {
  color: #3E3E3E;
}

.chat a {
  color: black;
  font-size: 22px;
}

.chat {
  margin-bottom: 72px;
}

.contact-page input:focus,
.contact-page textarea:focus {
  box-shadow: none;
  outline: 0px;
}

.contact-page input,
.contact-page textarea {
  background-color: transparent;
  padding-bottom: 10px;
  padding-top: 0px;
  border: 0px;
  border-bottom: 1px solid black;
  border-radius: 0px;
}

.out-side {
  width: 75%;
  margin-bottom: 40px;
}

.out-side p {
  margin-top: 20px;
}

.contact-page .inner-form {
  margin-right: 50px;
  border-radius: 30px;
  padding: 100px 60px;
  background-color: #BAFE66;
}

.contact-page .primary-button {
  width: 100%;
  background-color: black;
  border-color: transparent;
  color: white;
  display: block;
}

.contact-page .form-group {
  margin-bottom: 40px;
}

.contact-page .inner-form input::placeholder,
.contact-page .inner-form textarea::placeholder {
  color: black;
}

.contact-page .inner-form input:focus,
.contact-page .inner-form textarea:focus {
  background-color: transparent;
}

@media(max-width:768px) {
  .contact-page .inner-form {
    margin-bottom: 30px;
  }

  .social-side {
    margin-top: 0px;
  }

  .contact-page .row {
    flex-direction: column-reverse;
  }

  .chat {
    margin-bottom: 35px;
  }

  .image-co {
    display: none;
  }
}

@media(max-width:992px) {
  .contact-page .inner-form {
    margin-right: 0px;

    padding: 45px 30px;

  }

  .out-side {
    width: 100%;
  }

}

.footer {
  background-color: #001E00;
  padding: 100px 0px;
}

.footer .mail {
  display: inline-block;
  border-bottom: 2px solid white;
  padding-bottom: 12px;
}

.footer .icon svg {
  font-size: 50px;
  fill: white;
  margin-top: 35px;
}

.footer .mail a {
  color: white;
  font-size: 20px;
}

.footer .logo img {
  width: 150px;
}

.footer .items a {
  color: white;
  padding-bottom: 8px;
}

.footer .descripiton p {
  margin-top: 10px;
  color: white;
  margin-bottom: 20px;
  font-size: 21px;
}

.footer h2 {
  color: white;
  font-size: 35px;
}

.lets-talk svg {
  fill: white;
}

.number p {
  color: white;
  font-size: 18px;
}

.footer-wrap {
  margin-top: 48px;
}

.list-items ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 23px;
  fill: white;
  font-size: 25px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer .items a {
  color: white;
  padding-bottom: 8px;
  font-size: 20px;
  line-height: 2;
  transition: all 0.5s ease-in-out;
}

.footer .items a:hover {
  color: var(--primary_color);
}

.footer .items {
  display: flex;
  justify-content: center;
}

.button.mobile-button {
  margin-top: 20px;
}

.button.mobile-button .primary-button {
  line-height: 1.2;
}

.folww {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 25px;
  color: white;
}

.list-items svg {
  fill: white;
}

.footer-wrap .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-wrap .innre-box p {
  color: white;
  margin: 0px;
}

.items>ul>li>a,
.mail>a {
  text-decoration: none;
}

@media(max-width:992px) {
  .footer .items {
    display: flex;
    justify-content: flex-start;
  }

  .modal-content {
    width: 50% !important;
  }

  .mobile-button {
    display: block !Important;
  }

  .folww {
    justify-content: center;
  }

  .footer-wrap .row {
    flex-direction: column-reverse;
    text-align: center;
    gap: 20px;
  }

  footer h2 {
    color: white;
    font-size: 20px !important;
  }

  .header .button {
    display: none;
  }

  header .header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .button-container input {
    width: 45%;
  }

  .what_we_do ul li {
    width: 30%;
  }

  .hero_seciton .inner-img {
    margin-top: 45px;
  }
}

.mobile-button {
  display: none;
}

.mt30 {
  margin-top: 30px;
}

.f7f7-color {
  background: #f7f7f7;
}

.mt60 {
  margin-top: 60px;
}

.services-inner-box {
  background: #f2f7f2;
  position: relative;
  place-items: start;
  display: grid;
  border-radius: 14px;
  min-height: 359px;
  padding: 40px 30px;
  -webkit-box-shadow: 0 10px 15px -2px rgb(82 0 57/8%);
  box-shadow: 0 10px 15px -2px rgb(82 0 57/8%);
}

.services-inner-box-content a {
  color: inherit;
}

.services-inner-box-img {
  background: #fff;
  box-shadow: 0 15px 30px -10px rgb(0 0 0/14%);
  padding: 15px;
  border-radius: 14px;
  position: absolute;
  width: 80px;
  height: 80px;
  top: -30px;
  left: 30px;
}

.services-inner-box-img img {
  width: 100%;
}

.mt40 {
  margin-top: 40px;
}

.services-inner-box-content h3 {
  font-size: 28px;
  line-height: 38px;
}

.links-card {
  display: block;
  color: #14A800;
  font-size: 18px;
}

.links-card:hover {
  color: black;
}

.fa-btn {
  font-size: 11px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}

@media(max-width:768px) {
  .modal-content {
    width: 70% !important;
  }

  .list-items ul {
    justify-content: center;
  }

  .lets-talk {
    margin-top: 50px;
  }

  .footer {
    padding: 50px 0px;
  }

  .folww {
    margin-top: 20px;
    display: block;
  }

  .hero_seciton .shape {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;

  }

  .items {
    margin-top: 25px;
  }

  .hero_seciton .button-group {
    gap: 30px;
  }

  .hero_seciton .inner-box {
    margin-top: 40px;
  }

  .Schedule {
    padding: 60px 0px 60px 0px;
  }

  .section {
    padding: 50px 0px;
  }

  .sectionbottom {
    padding: 50px 0px;
  }

  .sectiontop {
    padding: 50px 0px;
  }

  .what_we_do ul li {
    width: 40%;
    min-height: 87px;
    font-size: 17px;
  }

  .hero_seciton {
    padding-bottom: 85px;
  }

  .hero_seciton .button-group {
    margin-top: 40px;
  }

}

@media(max-width:1600px) {
  .modal-content {
    width: 25%;
  }
}

@media(max-width:450px) {
  .modal-content {
    width: 90% !important;
  }

  .primary-button {
    padding: 12px 30px;
    font-size: 14px;
  }

  .Schedule .primary-button {
    font-size: 13px;
  }
}